<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Sensores - Notificaciones</h4>
            <div class="small text-muted">Configuración de los contactos que reciben notificaciones para este tanque</div>
          </b-col>          
        </b-row>
      </b-card>
      
      <b-row>
        <b-col lg="2" class="d-none d-lg-block" v-if="data.info.tank && data.info.type_asignation == 'tanque'">            
          
          <b-card no-body border-variant="light"> 
            <div class="text-center">    
              <span v-if="data.info.image">
                <b-card-img :src="data.info.image" bottom class="mt-3 detail-sensor-tank-img"></b-card-img>              
              </span>
              <span v-else>                    
                <b-card-img :src="data.info.tank.image" v-if="data.info.tank.image" bottom class="mt-3 detail-sensor-tank-img"></b-card-img>              
                <b-icon v-else icon="image" class="h1" v-b-tooltip.hover title="Imagen No Disponible" />     
              </span>
            </div>

            <b-card-body>                
              <b-card-title>{{data.info.tank.name}}</b-card-title>
              <b-card-sub-title class="mb-3">{{data.info.tank.code}}</b-card-sub-title>   
            
              <b-card-text>
                {{data.info.tank.description}} <br><br>
                <b>Diametro:</b> {{data.info.tank.diameter}} cm<br>
                <b>Alto:</b> {{data.info.tank.height}} cm<br>
                <b>Capacidad:</b> {{data.info.tank.capacity}} lt<br>
              </b-card-text>
            </b-card-body>

            <a :href="maps.urlMaps" target="_blank" v-if="maps.urlMaps">
              <div class="custom-mapa">                
                <l-map          
                  :options="maps.options"
                  :zoom="maps.zoom"
                  :center="maps.center"                    
                >
                  <l-tile-layer :url="maps.url"></l-tile-layer>
                    <l-marker :lat-lng="maps.markerLatLng" />                                  
                </l-map>                            
              </div> 
            </a>  
            <b-list-group flush>
              <b-list-group-item><b>Contenido: </b> {{data.info.type}}</b-list-group-item>
              <b-list-group-item><b>Serie: </b> {{data.info.serial_number}}</b-list-group-item>
              <b-list-group-item><b>Vto: </b> {{moment(data.info.expiration).format('DD/MM/YYYY')}}</b-list-group-item>
            </b-list-group>           
          </b-card>      

        </b-col>

        <b-col lg="2" class="d-none d-lg-block" v-if="data.info.type_asignation == 'bebedero'">            
          <b-card no-body border-variant="light"> 
            <div class="text-center">    
              <span v-if="data.info.image">
                <b-card-img :src="data.info.image" bottom class="mt-3 detail-sensor-tank-img"></b-card-img>              
              </span>            
            </div>
            
            <b-card-body>                
              <b-card-title>{{data.info.alias_trough}}</b-card-title>
            </b-card-body>

            <a :href="maps.urlMaps" target="_blank" v-if="maps.urlMaps">
              <div class="sensor-customer-detail-custom-mapa">                
                <l-map          
                  :options="maps.options"
                  :zoom="maps.zoom"
                  :center="maps.center"                    
                >
                  <l-tile-layer :url="maps.url"></l-tile-layer>
                    <l-marker :lat-lng="maps.markerLatLng" />                                  
                </l-map>                            
              </div> 
            </a>                    
          </b-card>      

        </b-col>

        <b-col lg="10" md="12" sm="12" xs="12">
          <b-card header-tag="header" footer-tag="footer">
            <b-row align-h="end" class="mb-3">
              <b-col md="8" sm="12" order="1"> 
                <h4><b>{{this.data.info.alias}}</b>
                <div class="small text-muted">Contactos para recibir notificaciones</div>                
                </h4>
                                
              </b-col>

              <b-col md="4" sm="12" order="2"> 
                
                <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Contactos para Notificaciones">
                  <b-dropdown-item @click="add()"><i class="fa fa-plus"></i> Agregar Contactos</b-dropdown-item>
                  <b-dropdown-item @click="addPush()"><i class="fa fa-plus"></i> Agregar Suscriptores Push</b-dropdown-item>
                </b-dropdown>

                <b-col sm="12" v-if="!table.mostrarFiltros">
                  <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                    <i class="fa fa-filter"></i> Mostrar Filtros
                  </b-button>
                </b-col>
                <b-col sm="12" v-else>
                  <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                    <i class="fa fa-window-close"></i>
                  </b-button>
                </b-col>

              </b-col>            
            </b-row>

            <b-row v-if="table.mostrarFiltros">
              <b-col sm="4">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                    <b-input-group-prepend>
                      <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                        <i class="fa fa-filter"></i> Filtrar
                      </b-button>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-table class="mb-0 crud-customers-contact-notification-table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        v-if="table.items.length">

                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template>

                    <template v-slot:cell(id)="data">                      
                      <b>{{ data.item.id }}</b>                      
                    </template>

                    <template v-slot:cell(name)="data">                      
                      <span v-html="data.item.name"></span>
                    </template>                    

                    <template v-slot:cell(type_alert)="data">                      
                      <div v-if="data.item.type_alert == 'minimum_capacity'">
                        <b>Capacidad Mínima (Primer Alerta)</b>
                      </div>  
                      <div v-if="data.item.type_alert == 'minimum_capacity_last'">
                        <b>Capacidad Mínima (Ultima Alerta)</b>
                      </div>                         
                      <div v-if="data.item.type_alert == 'disconnection_error'">
                        <b>Errores de Desconexión</b>
                      </div>   
                      <div v-if="data.item.type_alert == 'bebedero_status_close'">
                        <b>Estado Cerrado</b>
                      </div>                                               
                    </template>

                    <template v-slot:cell(notificationType)="data">                      
                      <b-badge variant="secondary" class="sensors-notifications-badge-type">{{ data.item.notification_type.name }}</b-badge>
                    </template>

                    <template v-slot:cell(edit)="data">                                       
                      <div v-if="data.item.m_notifications_type_id != constante.PUSH">
                        <div v-if="!data.item.group" >
                          <b-icon icon="exclamation-circle-fill" ></b-icon>
                          Edición Simple
                        </div>
                      </div>             
                      <div v-else>
                        <span v-html="data.item.observations"></span>
                      </div>                               
                    </template>                    

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="edit(data.item)" v-if="data.item.m_notifications_type_id != constante.PUSH">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row> 

          </b-card>

          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>
              </b-col>          
            </b-row>      
          </b-card> 
          
        </b-col>
      </b-row>                 

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Tipo de Alerta">
              <b-form-select v-model="crud.form.type_alert" :options="arr.typeAlert" size="sm"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Tipo de Notificación">
              <b-form-select v-model="crud.form.notificationTypeId" :options="arr.notificationType" size="sm"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"                            
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="this.const.notificationType.EMAIL == crud.form.notificationTypeId">
            <b-form-group label="Email">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.email"                            
                            placeholder="Ingresar un email">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="this.const.notificationType.SMS == crud.form.notificationTypeId || this.const.notificationType.WHATSAPP == crud.form.notificationTypeId">
            <b-form-group label="Celular">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.phone"                            
                            :placeholder="placeholderPhone">
              </b-form-input>
            </b-form-group>
          </b-col>                    
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD MAIN GROUP -->
      <b-modal v-model="modal.formGroup.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg">
        <div slot="modal-header">
          {{this.modal.formGroup.title}}
        </div>
        
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"                            
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Email">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.email"                            
                                placeholder="Ingresar un email">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="SMS">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.sms"                            
                                placeholder="Ingrese un nro sin el 0 ni el 15 (10 digitos total)">
                  </b-form-input>
                </b-form-group>
              </b-col>    
              <b-col md="12">
                <b-form-group label="Whatsapp">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.whatsapp"                            
                                placeholder="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
                  </b-form-input>
                </b-form-group>
              </b-col>                                  
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="12" v-if="this.data.info.type_asignation == 'tanque'">
                <b-form-group label="Capacidad Mínima (Primer Alerta)">
                  <v-select :options="arr.notificationTypeSelect" v-model="crud.form.alert1" placeholder="Tipos de Notificacion" :multiple="true" :select-on-tab="true"></v-select>
                </b-form-group>
                <b-form-group label="Capacidad Mínima (Ultima Alerta)">
                  <v-select :options="arr.notificationTypeSelect" v-model="crud.form.alert2" placeholder="Tipos de Notificacion" :multiple="true" :select-on-tab="true"></v-select>
                </b-form-group>
                <b-form-group label="Errores de Desconexión">
                  <v-select :options="arr.notificationTypeSelect" v-model="crud.form.alert3" placeholder="Tipos de Notificacion" :multiple="true" :select-on-tab="true"></v-select>
                </b-form-group>                                
              </b-col>

              <b-col md="12" v-if="this.data.info.type_asignation == 'bebedero'">
                <b-form-group label="Errores de Desconexión">
                  <v-select :options="arr.notificationTypeSelect" v-model="crud.form.alert3" placeholder="Tipos de Notificacion" :multiple="true" :select-on-tab="true"></v-select>
                </b-form-group>  
                <b-form-group label="Estado Cerrado">
                  <v-select :options="arr.notificationTypeSelect" v-model="crud.form.alert4" placeholder="Tipos de Notificacion" :multiple="true" :select-on-tab="true"></v-select>
                </b-form-group>                                                 
              </b-col>              
            </b-row>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formGroup.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PUSH -->
      <b-modal v-model="modal.formPush.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formPush.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-table class="mb-0 crud-customers-contact-notification-table"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="tablePush.items"
                    :fields="tablePush.fields"                              
                    v-if="tablePush.items.length">

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>

                <template v-slot:cell(id)="data">                      
                  <b>{{ data.item.id }}</b>                      
                </template>

                <template v-slot:cell(name)="data">                      
                  {{data.item.name}}
                </template>

                <template v-slot:cell(suscription)="data">                                        
                  {{moment(data.item.suscription).format('DD/MM/YYYY')}}
                </template>

                <template v-slot:cell(is_mobile)="data">                      
                  <b-link href="javascript:void(0);" :title="data.item.version">
                    <div v-if="data.item.is_mobile == 1">
                      <b><i class="fa fa-mobile-phone"></i> Mobile</b>
                    </div>
                    <div v-if="data.item.is_mobile == 0">
                      <b><i class="fa fa-desktop"></i> Desktop</b>
                    </div>
                    <div v-if="data.item.is_mobile == 2">
                      <b><i class="fa fa-desktop"></i> Desktop</b><br>
                      <b><i class="fa fa-mobile-phone"></i> Mobile</b>
                    </div> 
                  </b-link>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right" v-if="!isEnabledNotificationsPush(data.item)">
                    <b-dropdown-item @click="enabledNotificationsPush(data.item)">
                      <i class="fa fa-check ml-0 mr-0" style="color:green"></i> Habilitar
                    </b-dropdown-item>                   
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>            
          </b-col>                    
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPush.active=false">Cancelar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import ObjNotificationsTypes from '@/components/modules/users/notifications/config/type'  
  import moment from 'moment' 

  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
  import { Icon } from 'leaflet'
  import 'leaflet/dist/leaflet.css';  

  delete Icon.Default.prototype._getIconUrl
  
  Icon.Default.mergeOptions({    
    iconUrl: Session.getSession().settings.favicon,        
    shadowUrl: Session.getSession().settings.favicon,
    iconSize: [25, 25 * 1.15],
    iconAnchor: [25 / 2, 25 * 1.15],    
    shadowSize: [25, 25 * 1.15],
    shadowAnchor: [25 / 2, 25 * 1.15],        
  })

  export default {
    components: { 
      LMap,
      LTileLayer,
      LMarker,   
    },    
    data: () => {
      return {      
        customerID: 0,
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.CLIENTE,
          view_reference: 'crudSensorsNotifications',
          elements: {}
        }, 
        data: {
          id: 0,
          info: {},           
        },        
        maps: {
          urlMaps: '',
          url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
          options: {zoomControl: false, zoomSnap: true},          
          zoom: 12,
          center: [],
          markerLatLng: [],                  
        },   
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, width:'50px', class:'align-middle'},
            {key: 'type_alert', label: 'Tipo Alerta', width:'250px', class:'align-middle'},
            {key: 'notificationType', label: 'Tipo Notificación', width:'150px', class:'align-middle'},
            {key: 'name', label: 'Nombre', width:'100px', class:'align-middle'},            
            {key: 'phone', label: 'Celular', width:'150px', class:'align-middle'},
            {key: 'email', label: 'Email', width:'200px', class:'align-middle'},                        
            {key: 'edit', label: '', width:'200px', class:'align-middle'},
            {key: 'f_action', label:'', width:'100px', class:'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
        },      
        tablePush: {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, width:'10%', class:'align-middle'},
            {key: 'name', label: 'Nombre', width:'20%', class:'align-middle'},                        
            {key: 'is_mobile', label: 'Dispositivo Móvil', width:'20%', class:'align-middle text-center'},            
            {key: 'suscription', label: 'Suscripción', width:'10%', class:'align-middle text-center'},            
            {key: 'f_action', label:'', width:'10%', class:'align-middle'},
          ],
        },                 
        modal: {
          form: {
            active: false,
            title: ''
          },       
          formGroup: {
            active: false,
            title: ''
          },      
          formPush: {
            active: false,
            title: ''            
          }                   
        },
        crud: {
          form: {
            id: 0,
            notificationTypeId: 0,
            name: '',
            email: '',
            phone: '',
            type_alert: '',
            group: '',
            sms: '',
            whatsapp: '',
            alert1: [],
            alert2: [],
            alert3: [],
            alert4: [],
            action: 'add'            
          },
        },
        const: {
          notificationType: []
        },
        constante: {
          PUSH: ObjNotificationsTypes.PUSH,
          TYPE_ALERT_TANQUE: ['minimum_capacity', 'minimum_capacity_last', 'disconnection_error'],
          TYPE_ALERT_BEBEDERO: ['disconnection_error', 'bebedero_status_close'],
        },        
        arr: {
          notificationType: [],
          notificationTypeSelect: [],
          typeAlert: [],
          notificationPush: [],          
        }                
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Config Customer */      
      this.customerID = Helper.getCustomerId()
      /* Fin Config Customer */      
    },  
    mounted () {      
      this.data.id = this.$route.params.customerTankID  
      this.const.notificationType = ObjNotificationsTypes
      this.load()     
      this.loadContacts()    
      this.loadNotificationType()        
    },    
    computed: {
      placeholderPhone: function() {
        if(this.crud.form.notificationTypeId == this.const.notificationType.SMS) {
          return "Ingrese un nro sin el 0 ni el 15 (10 digitos total)"
        } else {
          if(this.crud.form.notificationTypeId == this.const.notificationType.WHATSAPP) {          
            return "Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)"
          }
        }
      }
    },
    methods: {
      validationCustomer(data) {
        if(this.customerID != data.m_customers_id) {
          this.$router.push({ name: 'Error403' })
        }
      },      
      load(){
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerVinculacionTanque(this.data)

        result.then((response) => {
          var data = response.data      
          this.data.info = data         

          // VALIDACION DE VISUALIZACION
          this.validationCustomer(data)
          // FIN VALIDACION

          if(data.lat && data.lng) {          
            this.maps.urlMaps = 'https://www.openstreetmap.org/#map=13/' + data.lat + '/' + data.lng
            this.maps.center = [data.lat, data.lng]
            this.maps.markerLatLng = [data.lat, data.lng]                  
          }
          
          this.loadTypeAlerts() 
          this.loadUsersNotificationsPush()     
          loader.hide()
        })
        .catch(error => {               
          loader.hide()
          this.$router.push({ name: 'Error500' })          
          //this.$awn.alert(Error.showError(error))
        });
      },
      loadContacts () {
        var result = serviceAPI.obtenerContactos(this.data)

        result.then((response) => {
          var data = response.data
          this.table.items = data         
          
          this.arr.notificationPush = []          
          data.forEach(element => {
            if(element.m_notifications_type_id == ObjNotificationsTypes.PUSH) {            
              this.arr.notificationPush.push(element)
            }  
          });            
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },
      loadNotificationType () {
        var result = serviceAPI.obtenerTiposNotificaciones()
        
        result.then((response) => {          
          var data = response.data        

          this.arr.notificationType = []
          this.arr.notificationTypeSelect = []

          data.forEach(element => { 
            if(element.id != ObjNotificationsTypes.PUSH) {           
              this.arr.notificationType.push({ value: element.id, text: element.name })            
              this.arr.notificationTypeSelect.push({ code: element.id, label: element.name })  
            }
          })
        })
      },      
      loadTypeAlerts() {
        this.arr.typeAlert = []
        if(this.data) {
          if(this.data.info.type_asignation == 'tanque') {
            this.arr.typeAlert.push({value:'minimum_capacity', text: 'Capacidad Mínima (Primer Alerta)'}) 
            this.arr.typeAlert.push({value:'minimum_capacity_last', text: 'Capacidad Mínima (Ultima Alerta)'}) 
            this.arr.typeAlert.push({value:'disconnection_error', text: 'Errores de Desconexión'}) 
          }

          if(this.data.info.type_asignation == 'bebedero') {          
            this.arr.typeAlert.push({value:'disconnection_error', text: 'Errores de Desconexión'}) 
            this.arr.typeAlert.push({value:'bebedero_status_close', text: 'Bebedero Cerrado'}) 
          }
        }
      },  

      add() {
        var uuid = require("uuid");

        this.crud.form.id = 0
        this.crud.form.type_alert = ''
        this.crud.form.notificationTypeId = ''
        this.crud.form.name = ''
        this.crud.form.email = ''
        this.crud.form.phone = ''
        this.crud.form.group = uuid.v4()
        this.crud.form.alert1 = [];
        this.crud.form.alert2 = [];
        this.crud.form.alert3 = [];       
        this.crud.form.alert4 = [];         
        this.crud.form.action = 'add'

        this.modal.formGroup.title = "Nuevo contacto para notificación"
        this.modal.formGroup.active = true
      },
      edit(item) {        
        if(item.group == null) {
          this.crud.form.id = item.id
          this.crud.form.type_alert = item.type_alert
          this.crud.form.notificationTypeId = item.m_notifications_type_id
          this.crud.form.name = item.name
          this.crud.form.email = item.email
          this.crud.form.phone = item.phone        
          this.crud.form.group = item.group
          this.crud.form.alert1 = [];
          this.crud.form.alert2 = [];
          this.crud.form.alert3 = [];       
          this.crud.form.alert4 = [];           
          this.crud.form.action = 'edit'
                    
          this.modal.form.title = "Editar contacto para notificación"
          this.modal.form.active = true
        } else {
          this.crud.form.id = item.id
          this.crud.form.name = '',
          this.crud.form.email = '',
          this.crud.form.phone = '',
          this.crud.form.whatsapp = '',          
          this.crud.form.group = item.group
          this.crud.form.action = 'edit'        
          this.crud.form.alert1 = [];
          this.crud.form.alert2 = [];
          this.crud.form.alert3 = [];  
          this.crud.form.alert4 = []; 
          this.formatDataContact(item)

          this.modal.formGroup.title = "Editar contacto para notificación"
          this.modal.formGroup.active = true
        }
      },      
      remove(item) {
        this.crud.form.id = item.id        
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar contacto para notificación',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarContacto(this.data.id, this.crud.form);

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.loadContacts()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      save() {
        if(this.crud.form.group) {
          this.saveGroup()
        } else {
          this.saveSimple()    
        }
      },
      saveSimple() {
        if(!this.crud.form.notificationTypeId) {
          this.$awn.alert("Debe seleccionar un tipo de notificación")          
          return false
        }

        if(this.crud.form.notificationTypeId == this.const.notificationType.EMAIL) {        
          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.email)) {          
            this.$awn.alert("El email no posee un formato válido")          
            return false
          }
        }

        if(this.crud.form.notificationTypeId == this.const.notificationType.SMS) {
          if(this.crud.form.phone.length != 10) {
            this.$awn.alert("El nro de télefono no posee un formato válido")
            return false
          }          
        }

        if(this.crud.form.notificationTypeId == this.const.notificationType.WHATSAPP) {
          if(this.crud.form.phone.length != 13) {
            this.$awn.alert("El nro de télefono no posee un formato válido")
            return false
          }
        }
        
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarContactos(this.data.id, this.crud.form);
        } else {
          var result = serviceAPI.agregarContactos(this.data.id, this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          this.modal.formGroup.active = false
          this.modal.formPush.active = false

          loader.hide()
          this.loadContacts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      saveGroup() {
        let loader = this.$loading.show();

        if(!this.crud.form.name) {
          this.$awn.alert("Debe cargar un nombre para el contacto")        
          loader.hide()     
          return false
        }
        if(!this.crud.form.alert1.length && !this.crud.form.alert2.length && !this.crud.form.alert3.length && !this.crud.form.alert4.length) {
          this.$awn.alert("Debe cargar como mínimo un modo de notificación para el contacto")          
          loader.hide()   
          return false
        }        

        var arrReg = []
        var status = true

        if(this.crud.form.alert1) {
          this.crud.form.alert1.forEach(element => {            
            if(this.validationSaveGroup(element.code)) {
              arrReg.push({                
                notificationTypeId: this.getNotificationType(element.code),
                name: this.crud.form.name,
                email: ( element.label == 'Email' ? this.crud.form.email : '' ),
                phone: ( element.label == 'SMS' ? this.crud.form.sms : ( element.label == 'Whatsapp' ? this.crud.form.whatsapp : '')),
                type_alert: 'minimum_capacity',
                group: this.crud.form.group,                
              })
            } else {
              status = false
            }       
          })
        }
        if(this.crud.form.alert2) {
          this.crud.form.alert2.forEach(element => {
            if(this.validationSaveGroup(element.code)) {
              arrReg.push({
                notificationTypeId: this.getNotificationType(element.code),
                name: this.crud.form.name,
                email: ( element.label == 'Email' ? this.crud.form.email : '' ),
                phone: ( element.label == 'SMS' ? this.crud.form.sms : ( element.label == 'Whatsapp' ? this.crud.form.whatsapp : '')),
                type_alert: 'minimum_capacity_last',
                group: this.crud.form.group,                
              })
            } else {
              status = false
            }                     
          })
        }
        if(this.crud.form.alert3) { 
          this.crud.form.alert3.forEach(element => {
            if(this.validationSaveGroup(element.code)) {
              arrReg.push({
                notificationTypeId: this.getNotificationType(element.code),
                name: this.crud.form.name,
                email: ( element.label == 'Email' ? this.crud.form.email : '' ),
                phone: ( element.label == 'SMS' ? this.crud.form.sms : ( element.label == 'Whatsapp' ? this.crud.form.whatsapp : '')),
                type_alert: 'disconnection_error',
                group: this.crud.form.group,                
              })
            } else {
              status = false
            }               
          })          
        }
        if(this.crud.form.alert4) { 
          this.crud.form.alert4.forEach(element => {
            if(this.validationSaveGroup(element.code)) {
              arrReg.push({
                notificationTypeId: this.getNotificationType(element.code),
                name: this.crud.form.name,
                email: ( element.label == 'Email' ? this.crud.form.email : '' ),
                phone: ( element.label == 'SMS' ? this.crud.form.sms : ( element.label == 'Whatsapp' ? this.crud.form.whatsapp : '')),
                type_alert: 'bebedero_status_close',
                group: this.crud.form.group,                
              })
            } else {
              status = false
            }               
          })          
        }

        if(!status) {
          loader.hide()
          return false          
        }        
              
        if (this.crud.form.action == 'edit') {
          var result = serviceAPI.editarContactosGroup(this.data.id, this.crud.form.id, {data: arrReg});
        } else {
          var result = serviceAPI.agregarContactosGroup(this.data.id, {data: arrReg});
        }

        result.then((response) => {
          this.modal.form.active = false
          this.modal.formGroup.active = false
          this.modal.formPush.active = false

          loader.hide()
          this.loadContacts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      validationSaveGroup(type) {        
        let result = true

        if(type == this.const.notificationType.EMAIL) {      
          if (!this.crud.form.email) {          
            this.$awn.alert("No se cargo el email")          
            result = false
          }

          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.email)) {          
            this.$awn.alert("El email no posee un formato válido")          
            result = false
          }
        }

        if(type == this.const.notificationType.SMS) {
          if(this.crud.form.sms.length != 10) {
            this.$awn.alert("El nro de télefono no posee un formato válido")
            result = false
          }          
        }

        if(type == this.const.notificationType.WHATSAPP) {
          if(this.crud.form.whatsapp.length != 13) {
            this.$awn.alert("El nro de télefono no posee un formato válido")
            result = false
          }
        }  
        
        return result
      },
      getNotificationType(type) {
        let result = 0

        if(type == this.const.notificationType.EMAIL) {        
          result = this.const.notificationType.EMAIL
        }

        if(type == this.const.notificationType.SMS) {
          result = this.const.notificationType.SMS
        }

        if(type == this.const.notificationType.WHATSAPP) {
          result = this.const.notificationType.WHATSAPP
        }  
        
        return result        
      },
      formatDataContact(item) {
        let loader = this.$loading.show();
        var result = serviceAPI.mostrarContactos(this.data.id, item.id)

        result.then((response) => {
          var data = response.data                
          
          if(data) {
            data.forEach(element => {
              if(!this.crud.form.name && element.name) {
                this.crud.form.name = element.name
              }
              
              if(!this.crud.form.email && element.email) {
                this.crud.form.email = element.email
              }
              
              if(!this.crud.form.sms && element.phone) {                
                if(element.m_notifications_type_id == this.const.notificationType.SMS) {
                  this.crud.form.sms = element.phone
                } 
              }

              if(!this.crud.form.whatsapp && element.phone) {                
                if(element.m_notifications_type_id == this.const.notificationType.WHATSAPP) {
                  this.crud.form.whatsapp = element.phone
                }                
              }                  
              
              if(element.type_alert == 'minimum_capacity') {
                this.arr.notificationTypeSelect.forEach(notify => {
                  if(notify.code == element.m_notifications_type_id) {
                    this.crud.form.alert1.push(notify)
                  }
                });
              }
              
              if(element.type_alert == 'minimum_capacity_last') {
                this.arr.notificationTypeSelect.forEach(notify => {
                  if(notify.code == element.m_notifications_type_id) {
                    this.crud.form.alert2.push(notify)
                  }
                });                
              }

              if(element.type_alert == 'disconnection_error') {
                this.arr.notificationTypeSelect.forEach(notify => {
                  if(notify.code == element.m_notifications_type_id) {
                    this.crud.form.alert3.push(notify)
                  }
                });                                
              }      

              if(element.type_alert == 'bebedero_status_close') {
                this.arr.notificationTypeSelect.forEach(notify => {
                  if(notify.code == element.m_notifications_type_id) {
                    this.crud.form.alert4.push(notify)
                  }
                });                                
              }                 
            });
          }

          loader.hide()
        })
        .catch(error => {               
          loader.hide()
          this.$router.push({ name: 'Error500' })          
          //this.$awn.alert(Error.showError(error))
        });        
      },
      
      addPush() {
        this.modal.formPush.title = 'Agregar Suscriptores de Notificaciones Push'
        this.modal.formPush.active = true
      },
      loadUsersNotificationsPush() {
        if(this.data && this.data.info && this.data.info.m_customers_id) {
          var result = serviceAPI.obtenerUsuariosNotificacionesPush({
            customer_id: this.customerID
          })

          result.then((response) => {
            var data = response.data

            var arrData = []
            data.forEach(element => {
              if(arrData[element.id] == undefined) {
                arrData[element.id] = []
                arrData[element.id] = element
              } else {
                if(arrData[element.id].is_mobile != element.is_mobile) {
                  arrData[element.id].is_mobile = 2
                }
                if(moment(arrData[element.id].suscription) < moment(element.suscription)) {
                  arrData[element.id].suscription = element.suscription
                }                
              }              
            });

            var arrData1 = []
            for (const key in arrData) {
              if (Object.hasOwnProperty.call(arrData, key)) {
                arrData1.push(arrData[key]);
              }
            }
            data = arrData1

            this.tablePush.items = data
          })          
        }
      },
      isEnabledNotificationsPush(item) {
        var count_type_alert = 0
        if(this.data.info.type_asignation == 'tanque') {
          count_type_alert = this.constante.TYPE_ALERT_TANQUE.length
        }
        if(this.data.info.type_asignation == 'bebedero'){
          count_type_alert = this.constante.TYPE_ALERT_BEBEDERO.length
        }        
        

        var count = 0        
        this.arr.notificationPush.forEach(element => {               
          if(element.user_id == item.id) {
            count = count + 1
          }
        })

        if(count == count_type_alert) {
          return true
        } else {
          return false
        }
      },
      enabledNotificationsPush(item) {        
        this.$bvModal.msgBoxConfirm('¿Habilitar envío de todas las alertas?', {
          title: 'Notificaciones Push',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'HABILITAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {           
            let loader = this.$loading.show();

            var type_alert = []
            if(this.data.info.type_asignation == 'tanque') {
              type_alert = this.constante.TYPE_ALERT_TANQUE
            }
            if(this.data.info.type_asignation == 'bebedero'){
              type_alert = this.constante.TYPE_ALERT_BEBEDERO
            }

            var data = {
              id: 0,
              customerTankID: this.data.id,
              notificationTypeId: ObjNotificationsTypes.PUSH, 
              user_id: item.id,  
              user_name: item.name,      
              is_mobile: item.is_mobile,
              version: item.version,              
              type_alert: type_alert,
            }

            var result = serviceAPI.agregarContactosPush(data);

            result.then((response) => {
              this.modal.form.active = false
              this.modal.formGroup.active = false          
              this.modal.formPush.active = false

              loader.hide()
              this.loadContacts()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })               
      },      
    }
  }
</script>
<style>
  .sensor-detail-small-info-tank {
    cursor: pointer;
  }
  .custom-mapa {
    padding: 0px;
    height: 150px;
  }
  .detail-sensor-tank-img {
    width: 90%;
  }
  .sensors-notifications-badge-type {
    font-size: 15px;
  }
  .crud-customers-contact-notification-table {
    min-height: 100px;
  }  
</style>